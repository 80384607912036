<template>
	<div class="Withdraw">
		
		<div class="Top">
			<div class="Left">
				提现详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回提现列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li v-if="Withdraw != null && Withdraw.Id != 'add'">
					<span>提现任务ID</span>
					<em>
						{{Withdraw.Id}}
					</em>
				</li>
				
				<li>
					<span>提现收款通道</span>
					<em  v-if="Withdraw != null">
						{{Withdraw.WithdrawAccountTypeName}}
					</em>
				</li>
				
				<li v-if="Withdraw.WithdrawAccountType == 'bank'">
					<span>提现收款银行</span>
					<em>
						{{Withdraw.WithdrawBankName}}
					</em>
				</li>
				
				<li>
					<span>收款人</span>
					<em>
						{{Withdraw.WithdrawHolder}}
					</em>
				</li>
				
				<li>
					<span>收款账号</span>
					<em>
						{{Withdraw.WithdrawNo}}
					</em>
				</li>
				
				<li>
					<span>提现资金</span>
					<em>
						{{Withdraw.WithdrawAmount}}
						<spa>
							手续费 {{Withdraw.WithdrawCommissio}},总消耗 {{Withdraw.WithdrawTotal}}
						</spa>
					</em>
				</li>
				
				<li>
					<span>提现状态</span>
					<em>
						{{Withdraw.StatusName}}
					</em>
				</li>
				
				<li>
					<span>审核人Hid</span>
					<em>
						{{Withdraw.AuditorHid}}
					</em>
				</li>
				
				<li>
					<span>审核内容</span>
					<em>
						{{Withdraw.AuditRemark}}
					</em>
				</li>
				
				<li>
					<span>审核时间</span>
					<em>
						{{Withdraw.AuditTime}}
					</em>
				</li>
				
				<li>
					<span>审核凭证</span>
					<em>
						<a :href="Withdraw.AuditProof" target="_blank">
							<img title="点击查看大图" :src="Withdraw.AuditProof" style="max-height: 150px;" />
						</a>
					</em>
				</li>
				
				
				<li v-if="Withdraw != null && Withdraw.Id != 'add'">
					<span>创建时间</span>
					<em>
						{{Withdraw.CreatedAt}}
					</em>
				</li>
				
				<li v-if="Withdraw != null && Withdraw.Id != 'add'">
					<span>最后修改时间</span>
					<em>
						{{Withdraw.UpdatedAt}}
					</em>
				</li>
				
				<li>
					<span></span>
					<em>
						<el-button  @click="CheckDialog = true" type="danger">确认打款</el-button>
						<el-button  @click="RefuseDialog = true" type="info">驳回申请</el-button>
					</em>
				</li>
			</div>
		</div>
		
		<el-dialog
		  title="提现驳回"
		  :visible.sync="RefuseDialog"
		  width="400px"
		  :before-close="RefuseDialogClose">
		  <li style="font-size: 0.9rem;color: #888888;margin-bottom: 10px;">驳回理由：</li>
		  <li style="margin-bottom: 20px;">
			  <el-input v-model="AuditRemark"></el-input>
		  </li>
		   <li style="font-size: 0.9rem;color: #888888;margin-bottom: 10px;">截图补充：</li>
		  <li style="margin-bottom: 20px;">
		  			
		  			
		  				<el-upload
		  				  action="https://jsonplaceholder.typicode.com/posts/"
		  				  :show-file-list="false" :on-change="getFileChange" :limit="1" :auto-upload="false">
		  				  <div style="width: 100px;height: 100px;border-radius: 5px;display: flex;align-items: center;justify-content: center;border: 1px solid rgba(0,0,0,0.1);cursor: pointer;font-size: 2rem;color: rgba(0,0,0,0.2);" v-if="AuditProof == ''">
		  					+
		  				 </div>
		  				 
		  				 <img v-else :src="AuditProof" style="width: 100px;height: 100px;border-radius: 5px;border: 1px solid rgba(0,0,0,0.1);cursor: pointer;" />
		  				 
		  				</el-upload>
		  			
		  			
		  			
		  		
		  			
		  </li>
		  <span>
			  <el-button type="primary" @click="RefuseAct()">确 定</el-button>
		    <el-button @click="RefuseDialogClose()">取 消</el-button>
		  </span>
		</el-dialog>
		
		<el-dialog
		  title="提现确认"
		  :visible.sync="CheckDialog"
		  width="400px"
		  :before-close="CheckDialogClose">
		  <li style="font-size: 0.9rem;color: #888888;margin-bottom: 10px;">付款说明(一般是付款流水号)：</li>
		  <li style="margin-bottom: 20px;">
		  		<el-input v-model="AuditRemark"></el-input>
		  </li>
		   <li style="font-size: 0.9rem;color: #888888;margin-bottom: 10px;">付款截图凭证：</li>
		  <li style="margin-bottom: 20px;">
			
			
				<el-upload
				  action="https://jsonplaceholder.typicode.com/posts/"
				  :show-file-list="false" :on-change="getFileChange" :limit="1" :auto-upload="false">
				  <div style="width: 100px;height: 100px;border-radius: 5px;display: flex;align-items: center;justify-content: center;border: 1px solid rgba(0,0,0,0.1);cursor: pointer;font-size: 2rem;color: rgba(0,0,0,0.2);" v-if="AuditProof == ''">
					+
				 </div>
				 
				 <img v-else :src="AuditProof" style="width: 100px;height: 100px;border-radius: 5px;border: 1px solid rgba(0,0,0,0.1);cursor: pointer;" />
				 
				</el-upload>
			
			
		  </li>
		  <li style="font-size: 0.9rem;color: #888888;margin-bottom: 10px;">打款账号：</li>
		  <li style="margin-bottom: 20px;">
		  		<el-select v-model="PayAccountId" style="display: inline-block;width: 100%;">
					<el-option v-for="(item,itemI) in WithdrawPayAccountList" :label="item.TypeName + item.BankName + '-' + item.Holder + '-' + item.No" :value="item.Id" :key="itemI"></el-option>
				</el-select>
		  </li>
		  <span>
			  <el-button type="primary" @click="CheckAct()">确 定</el-button>
		    <el-button @click="CheckDialogClose()">取 消</el-button>
		  </span>
		</el-dialog>
		
	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload} from 'element-ui'
	export default {
	  name: 'WithdrawInfo',
	  props: {
	  },
	  data() {
	      return {
			  Withdraw:{
				  Id:'',
				  WithdrawAccountType:'',
				  WithdrawAccountTypeName:'',
				  WithdrawBankName:'',
				  WithdrawHolder:'',
				  WithdrawNo:'',
				  Status:50,
				  StatusName:'',
				  AuditTime:'',
				  AuditorHid:'',
				  AuditRemark:'',
				  AuditProof:'',
				  WithdrawAmount:'',
				  WithdrawCommissio:'',
				  WithdrawTotal:'',
				  CreatedAt:'',
				  UpdatedAt:'',
			  },
			  RefuseDialog:false,
			  AuditRemark:'',
			  AuditProof:'',//审核凭证图片
			  CheckDialog:false,//提现确认框
			  WithdrawPayAccountList:[],//提现付款账号列表
			  PayAccountId:'',
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少提现ID，页面无法工作')
			return
		}
		this.GetWithdraw(this.$route.params.Id)
		this.GetWithdrawPayAccountList()
	  },
	  methods:{
		  RefuseDialogClose(){
		  	this.RefuseDialog = false
		  },
		  CheckDialogClose(){
			  this.CheckDialog = false
		  },
		  GetWithdrawPayAccountList(){
		  	let data = {Service:'Finance',Class: 'FinanceWithdrawPayAccount',Action: 'List',Status:50,Page:1,PageSize:50}
		  	this.$post(this.$store.getters.getApiHost,data)
		  	.then((res) => {
		  		
		  		if(res.ErrorId != 0){
		  			this.$message(res.Msg)
		  			return
		  		}
		  		this.WithdrawPayAccountList = res.Data.FinanceWithdrawPayAccountList
		  	})
		  },
		    GetWithdraw(_id){
		  		let data = {Service:'Finance',Class: 'FinanceWithdraw',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.Withdraw = res.Data
		  		})
		    },
			UpdateWithdraw(){
				let that = this
				let data = {Service:'Withdraw',Class: 'Withdraw',Action: 'Update',Id:that.Withdraw.Id,Status:that.Withdraw.Status,Name:that.Withdraw.Name,Logo:that.Withdraw.Logo,CategoryOne:that.Withdraw.CategoryOne,CategoryTwo:that.Withdraw.CategoryTwo,CategoryThree:that.Withdraw.CategoryThree}
				if(that.Withdraw.Id == 'add'){
					data.Action = 'Add'
					data.Id = ''
				}
				this.$post(that.$store.getters.getApiHost,data)
				.then((res) => {
					that.$message(res.Msg)
					if(res.ErrorId == 0){
						that.$Jump('/do/Withdraw/Withdraw_list')
					}
				})
			},
			RefuseAct(){
				let data = {Service:'Finance',Class: 'FinanceWithdraw',Action: 'Refuse',Id:this.Withdraw.Id,AuditRemark:this.AuditRemark,AuditProof:this.AuditProof}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
					if(res.ErrorId == 0){
						this.RefuseDialog = false
						this.GetWithdraw(this.Withdraw.Id)
					}
				})
			},
			CheckAct(){
				let data = {Service:'Finance',Class: 'FinanceWithdraw',Action: 'Check',Id:this.Withdraw.Id,AuditRemark:this.AuditRemark,AuditProof:this.AuditProof}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
					if(res.ErrorId == 0){
						this.CheckDialog = false
						this.GetWithdraw(this.Withdraw.Id)
					}
				})
			},
			getFilesChange(file, fileList) {
						  this.uploadModel = 'multiple'
							this.getBase64(file.raw).then(res => {
								this.uploadPic(res)
							});
			},
			getFileChange(file, fileList) {
						this.uploadModel = 'single'
						this.getBase64(file.raw).then(res => {
							this.uploadPic(res)
						});
			},
			getBase64(file) {
			      return new Promise(function(resolve, reject) {
			        let reader = new FileReader();
			        let imgResult = "";
			        reader.readAsDataURL(file);
			        reader.onload = function() {
			          imgResult = reader.result;
			        };
			        reader.onerror = function(error) {
			          reject(error);
			        };
			        reader.onloadend = function() {
			          resolve(imgResult);
			        };
			      });
			    },
						  uploadPic(_b64){
							  let that = this
							  let data = {
							  	Service:'Goods',
							  	Class: 'File',
							  	Action: 'Base64Upload',
							  	Base64:_b64
							  }
							  this.$post(that.$store.getters.getApiHost,data)
							  .then((res) => {
							  	
							  	if(res.ErrorId != 0){
							  		that.$message(res.Msg)
							  		return
							  	}
							  	
								that.AuditProof = res.Data.Url
							  	
							  })
							  .catch(function (response) {
							  	that.$message('网络请求错误')
							  })
						  },
	  }
	}
</script>

<style scoped>
.Withdraw{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Withdraw .Top{
	display: flex;
	align-items: center;
}
.Withdraw .Top .Left{
	
}
.Withdraw .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Withdraw .List{
	margin-top: 20px;	
}
.Withdraw .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Withdraw .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Withdraw .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.Withdraw .List .One li  em{
	flex: 1;
}
.Withdraw .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 64px;
    height: 64;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }
</style>
